import { Box, Button } from '@mui/material';
import React from 'react';
import { themeColor } from './shared';
import styled from '@emotion/styled';


const boxStyle = {
    display: "block",
    width: "100%",
};

const CustomButton = styled(Button)`
    border-radius: 50%;
    transition: ${themeColor} 0.3s;
    &:hover {
        background-color: rgba(4, 99, 7, 0.3);
    }
`;

const imgStyle = {
    width: 100,
    height: 100,
};

const titleStyle = {
    fontWeight: "bold",
    color: themeColor,
};

interface IconProps {
    srcImage: any,
    title: string,
    subtitle: string,
    onTap: () => void,
};

function ExperienceIcon(props: IconProps) {
    return (
        <Box style={boxStyle}>
            <CustomButton onClick={props.onTap}>
                <img src={require(`${props.srcImage}`)} style={imgStyle} />
            </CustomButton>
            {props.title ? (<p style={titleStyle}>{props.title}</p>) : <></>}
            {props.subtitle ? (<p>{props.subtitle}</p>) : <></>}
        </Box>
    );
}

export default ExperienceIcon;