import { ImageList, ImageListItem, Link, Typography, Dialog, DialogContent, CircularProgress } from "@mui/material";
import { useState } from "react";
import { themeColor } from "./shared";


interface ProjectProps {
    title: string,
    description: string,
    images: string[],
    link: string,
};

const projectTitle = {
    color: themeColor,
    fontWeight: "bold",
}

interface ImageWithPlaceholderProps {
    src: string;
    alt: string;
}

const ImageWithPlaceholder = (props: ImageWithPlaceholderProps) => {
    const { src, alt } = props;
    const [loaded, setLoaded] = useState(false);

    const handleImageLoad = () => {
        setLoaded(true);
    };

    return (
        <>
            {!loaded && <div style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}></div>}
            <img src={src} alt={alt} onLoad={handleImageLoad} style={{ opacity: loaded ? 1 : 0, transition: 'opacity 0.3s ease-in-out' }} />
        </>
    );
};

export default function ProjectDetails(props: ProjectProps) {
    const { title, description, images, link } = props;
    const [open, setOpen] = useState(false);
    const [selectedImage, setSelectedImage] = useState("");

    const handleClickOpen = (image: string) => {
        setSelectedImage(image);
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return <div>
        {link ?
            <Typography style={projectTitle}>
                <Link href={link} underline="hover" rel="noopener noreferrer" target="_blank" color="inherit">{title}</Link>
            </Typography>
            :
            <Typography style={projectTitle}>
                {title}
            </Typography>
        }

        <Typography variant="caption">
            {description}
        </Typography>

        {images.length > 0 ?
            <ImageList
                sx={{
                    gridAutoFlow: "column",
                    gridTemplateColumns: "repeat(auto-fill,minmax(100px,1fr)) !important",
                    gridAutoColumns: "minmax(100px, 1fr)",
                    gap: "25px",

                }}>
                {images.map((item) => (
                        <ImageListItem key={item} onClick={() => handleClickOpen(item)}>
                            <ImageWithPlaceholder
                                src={require(`./images/${item}`)}
                                alt="Your Image"
                            />
                        </ImageListItem>
                ))}
            </ImageList>
            : <></>}

        <Dialog open={open} onClose={handleClose}>
            <DialogContent >
                {selectedImage && (
                    <img src={require(`./images/${selectedImage}`)} alt="Selected" style={{ width: '100%', height: '90%' }} />
                )}
            </DialogContent>
        </Dialog>

    </div>
}