import { Container, CssBaseline, Grid, ThemeProvider, createTheme, Link } from '@mui/material';
import ExperienceIcon from './ExperienceIcon';
import { themeColor } from './shared';
import ExperienceItem from './ExperienceItem';
import { getIconList, getLinks } from './model/abi';
import { useState } from 'react';
import useMediaQuery from '@mui/material/useMediaQuery';
import styled from '@emotion/styled';
import IBM_Plex_Mono from './fonts/IBM_Plex_Mono/IBMPlexMono-Regular.ttf';
import FlyingText from './FlyingText';



const maxScreenWidth = '600px';

const theme = createTheme({
  palette: {
    background: {
      default: '#faf0e6', // Set the default background color
    },
  },
  typography: {
    fontFamily: 'ibm-plex-mono',
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: `
        @font-face {
          font-family: 'ibm-plex-mono';
          src: local('ibm-plex-mono'), url(${IBM_Plex_Mono}) format('truetype');
        }
      `,
    },
  },
});

const headerStyle = {
  fontWeight: "bold",
  fontSize: "50px",
  color: themeColor,
  textAlign: 'center',
  padding: "30px",
};

const h2Style = {
  fontWeight: "bold",
  color: themeColor,
};

const linkStyle = {
  color: themeColor,
  textDecorationColor: themeColor
}

const safeSpace = {
  alignItems: 'center',
  // justifyContent: 'center',
  // alignContent: 'flex-start',
  alignContent: 'center',
};

const containerStyle = {
  marginTop: 50,
  marginBottom: 50,
  maxWidth: maxScreenWidth,
  textAlign: 'center',
  alignItems: 'center',
  alignContent: 'center',
  justifyContent: 'center',
};

const gridStyle = {
  alignItems: 'center',
  justifyContent: 'center'
}

const logoStyle = {
  alignItems: 'center',
  alignContent: 'center',
};

const Line = styled.div`
  border-left: 5px;
  height: 100%;
  width: 2px;
  background-color: #333;
  margin: 0 auto; 
`;

const links = getLinks();
const iconList = getIconList();
const ac_char = './images/ac_char.png';
function App() {
  const largeScreen = useMediaQuery(`(min-width:${maxScreenWidth})`);
  const [messages, setMessages] = useState([]);
  // TODO: padding right on heading has issues

  const secretTap = () => {
    const newMessages = [...messages, <FlyingText key={Date.now()} onHide={handleHide} />];
    setMessages(newMessages);
  };

  const handleHide = () => {
    const newMessages = messages.slice(1); // Remove the first message
    setMessages(newMessages);
  };

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <div className="App">
        <Container style={containerStyle}>
          {messages}
          <Grid container direction={'column'} columnGap={2} style={safeSpace}>
            <Grid container direction={'row'} style={gridStyle}>
              <Grid item style={logoStyle}>
                <ExperienceIcon srcImage={ac_char} onTap={secretTap}/>
              </Grid>
              <Grid item >
                <div style={headerStyle}>
                  ABIGAIL CHIN
                </div>
              </Grid>
            </Grid>
            <Grid container direction={largeScreen ? 'row' : 'column'} alignItems={'flex-start'} justifyContent={'center'} marginY={"20px"}>
              {iconList.map((l) => {
                const isLast = iconList[iconList.length - 1].name === l.name;
                return (
                  <Grid container direction={'column'} xs style={gridStyle} borderRight={isLast || !largeScreen ? "none" : "dashed"} borderBottom={isLast && !largeScreen ? "dashed" : "none"} borderTop={largeScreen ? "none" : "dashed"} borderColor={themeColor} >
                    <h2 style={h2Style}>{l.name}</h2>
                    {l.items.map((i) => {
                      return (
                        <ExperienceItem data={i} />
                      );
                    })}
                  </Grid>
                );
              })}
              <Line />
            </Grid>
            <Grid container direction={'row'} style={gridStyle}>
              {links.map((l) => {
                return (
                  <Grid item >
                    <>
                      <Link href={l.href} style={linkStyle} rel="noopener noreferrer" target="_blank"> {l.title}</Link>
                      {links.at(links.length - 1) != l ? <>{"|"}</> : <></>}
                    </>
                  </Grid>
                );
              })}
            </Grid>
          </Grid>
        </Container>
      </div>
    </ThemeProvider>

  );
}

export default App;
