import data from './data.json';

interface Experience {
    id: number;
    img: string;
    title: string;
    sub: string;   
    details?: Details;
    projects: Project[];
}

interface Details {
    description: string;
    location: string;
    startDate: string;
    endDate: string;
}

interface Project {
    title: string;
    description: string;
    images: string[];
    link: string;
}

interface ExpListCategory {
    name: string;
    items: Experience[];
}

interface Link {
    href: string;
    title: string;
}

const getIcons = (): Experience[] => {
    return data.experience;
};

export const getExperienceById = (id: number): Experience | undefined => {
    return data.experience.find((experience) => experience.id === id);
};

export const getIconList = (): ExpListCategory[] => {
    const icons = getIcons();
    return data.expList.map((innerList,) => {
        const name = String(innerList[0]); // You can use any key naming logic here
        const nums = innerList[1] as number[];
        const items: Experience[] = icons.slice(nums[0], nums[1]);
        return { name, items };
      });
};

export const getLinks = (): Link[] => {
    return data.links;
};
