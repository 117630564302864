import styled from "@emotion/styled";
import { themeColor } from "./shared";
import { keyframes } from "@emotion/react";
import { useEffect, useState } from "react";

const flyAnimation = keyframes`
  from {
    transform: translateY(0%);
    opacity: 1;
}
to {
    transform: translateY(-50%);
    opacity: 0;
  }
`;

const FText = styled.div`
  font-size: 12px;
  color: ${themeColor};
  position: absolute;
  animation: ${flyAnimation} 3s linear infinite;
`;

interface FlyingTextProps {
    onHide: ()=>void;
}


export default function FlyingText(props: FlyingTextProps) {
    const [isVisible, setIsVisible] = useState(true);

    useEffect(() => {
        const timer = setTimeout(() => {
            setIsVisible(false);
        }, 3000); // Adjust the duration as needed (in milliseconds)

        return () => clearTimeout(timer);
    }, [props.onHide]);

    return (
        <>
            {isVisible ? <FText>
                <p> hello</p>
            </FText > : <></>
            }
        </>
    );
};