import { Box, Dialog, DialogContent, DialogProps, DialogTitle, Grid, IconButton, Typography } from "@mui/material";
import ExperienceIcon from "./ExperienceIcon";
import { useState } from "react";
import { bgColor, themeColor } from "./shared";
import { getExperienceById } from "./model/abi";
import { X } from "react-feather";
import ProjectDetails from "./ProjectDetails";

interface ExperienceProps {
    data: {
        id: number,
        img: string,
        title: string,
        sub: string,
    }
};

const h2 = {
    fontWeight: 'bold',
}

const subtitle = {
    color: themeColor,
    fontSize: 12,
}

function ExperienceItem(props: ExperienceProps) {
    const { id, img, title, sub } = props.data;
    const [open, setOpen] = useState(false);
    const [maxWidth, setMaxWidth] = useState<DialogProps['maxWidth']>('sm');

    const exp = getExperienceById(id)

    const handleTap = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const projects = () => {
        return exp?.projects.map((p) => <ProjectDetails {...p} />);
    };

    const getDateString = (dateStr: string) => {
        const [year, month] = dateStr.split("-");
        const dateObject: Date = new Date(parseInt(year), parseInt(month) - 1, 1);
        const monthName: string = dateObject.toLocaleString('default', { month: 'long' });
        const result: string = `${monthName} ${year}`;
        return result;
    };

    const getSubtitle = () => {
        const details = exp?.details;
        if (details) {
            const timePeriod = `${getDateString(details.startDate)} to ${getDateString(details.endDate)}`;
            return `${details.description} | ${details.location} | ${timePeriod}`
        }
        return ""
    };

    const padding = () => <div style={{ marginTop: '10px' }}></div>;

    return (
        <div>
            <Grid item >
                <ExperienceIcon srcImage={img} title={title} subtitle={sub} onTap={handleTap} />
            </Grid>

            <Dialog
                maxWidth={maxWidth}
                open={open}
                onClose={handleClose}
                scroll="body"
                PaperProps={{
                    style: {
                        backgroundColor: bgColor,
                    },
                }}
            >
                <DialogTitle color={themeColor} fontWeight={"bold"}>
                    {title}
                    <Typography style={subtitle}>
                        {getSubtitle()}
                    </Typography>
                </DialogTitle>
               
                <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <X />
                </IconButton>
                <DialogContent dividers  >
                    
                    {/* {padding()} */}
                    {exp?.projects ?
                        <>
                            {projects()}
                        </>
                        : <></>
                    }
                    {/* <Box
                        noValidate
                        component="form"
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            m: 'auto',
                            width: 'fit-content',
                        }}
                    >  </Box> */}

                </DialogContent>
            </Dialog>
        </div>
    );
}



export default ExperienceItem;